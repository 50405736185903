@media only screen and (max-width: 800px) {
  .MediaViewer {
      width: 100%;
  }
}
  
  @media only screen and (min-width: 801px) {
  .MediaViewer {
    margin: 0 auto;
    width: 75%;
    max-width: 800px;
  }
}
