@media only screen and (max-width: 800px) {
    .TravelMap {
        height: 200px;
        width: 100%;
    }
}

@media only screen and (min-width: 801px) {
    .TravelMap {
        height: 250px;
        width: 100%;
    }
}

.MapFullscreen {
    width: 100vw;
    height: calc(100vh - 120px);
}