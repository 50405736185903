@media only screen and (max-width: 800px) {
    #authorCol, #mediaCol, #commentsCol, #viewCol {
        display: none;
    }
    .location-table {
        min-width: 250px;
    }
}
    
@media only screen and (min-width: 801px) {
    .location-table {
        min-width: 650px;
    }
}