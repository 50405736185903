@media only screen and (max-width: 800px) {
  .App {
    text-align: center;
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 0;
  }

  .App-header {
    grid-column: 1;
    grid-row: 1;
  }
  .App-details {
    grid-column: 1;
    grid-row: 2;
  }
  .App-map {
    grid-column: 1;
    grid-row: 3;
  }
  .App-stats {
    grid-column: 1;
    grid-row: 4;
  }
  .App-posts {
    grid-column: 1;
    grid-row: 5;
  }
}

@media only screen and (min-width: 801px) {
  .App {
    text-align: center;
    display: grid;
    grid-template-columns: 44% 55%;
    grid-gap: 0;
  }

  .App-header {
    grid-column: 1 / 3;
    grid-row: 1;
  }
  .App-details {
    grid-column: 1;
    grid-row: 2;
  }
  .App-map {
    grid-column: 2;
    grid-row: 2;
  }
  .App-posts {
    grid-column: 1 / 3;
    grid-row: 3;
  }
}

/* 
.color1 {
  color: #6f584b
}
.color2 {
  color: #4e6e5d
}
.color3 {
  color: #1c6e8c
}
.color4 {
  color: #4d5057
}
.color5 {
  color: #000000
} */