@media only screen and (max-width: 800px) {
    .postcomments {
        width: 96%;
        margin: .5em auto;
    }
    .AddCommentButton {
        width: 100%;
        margin-bottom: 1em;
    }
  }
    
    @media only screen and (min-width: 801px) {
    .postcomments {
      width: 75%;
      margin: .5em auto;
    }
    .AddCommentButton {
        width: 100%;
    }
  }
  